<template>
  <div class="map flex position-r">
    <transition-group tag="div" class="VueOnly" name="list" appear>
      <div
        class="map__circle"
        v-for="(item, index) in selectedAttributesDetails"
        :key="item.xPos"
        :style="{
          left: item.xPos / 10 + '%',
          top: item.yPos / 6.69 + '%',
        }"
        :class="{
          'is-active': item.showPin,
        }"
      >
        <span class="map__trigger-title">{{ item.AttributeLabel }}</span>
        <span
          class="map__trigger"
          @click="
            selectedAttributes[index].showPin =
              !selectedAttributes[index].showPin
          "
        ></span>
        <span class="map__tooltip">
          <span
            ><strong>{{ item.AttributeLabel }}</strong></span
          >
          <span>{{ item.synonymLabel }}</span>
        </span>
      </div>
    </transition-group>
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 900 593"
      style="enable-background: new 0 0 900 593"
      xml:space="preserve"
    >
      <g id="mapSlide">
        <path
          class="Fun st0 el1"
          d="M117.9,410.1l-67.6,23.1c51.9,65.7,140.1,116.9,247,142.2l25.7-47.2C234.3,507.2,161.1,464.6,117.9,410.1z"
          :class="{
            dimOut: selectedZone1 !== 'Fun' && selectedZone2 !== 'Fun',
          }"
        ></path>
        <path
          class="Inspiring st0 el2"
          d="M293.1,72.7L261.5,27C153.9,59.7,69.2,119.5,27.6,193.2l71.3,17.6C133.4,149.6,203.7,99.9,293.1,72.7z"
          :class="{
            dimOut:
              selectedZone1 !== 'Inspiring' && selectedZone2 !== 'Inspiring',
          }"
        ></path>
        <path
          class="Trustworthy st0 el3"
          d="M779,179.9l67-23.8C786.1,82.8,680.7,28,554.8,8.1l-17.9,48.8C641.6,73.4,729.2,118.9,779,179.9z"
          :class="{
            dimOut:
              selectedZone1 !== 'Trustworthy' &&
              selectedZone2 !== 'Trustworthy',
          }"
        ></path>
        <path
          class="Nurturing st0 el4"
          d="M544,534.7l19.4,48.5c131.4-22.6,239.4-83.2,295.1-163l-69.3-20.8C742.8,465.7,653.1,516,544,534.7z"
          :class="{
            dimOut:
              selectedZone1 !== 'Nurturing' && selectedZone2 !== 'Nurturing',
          }"
        ></path>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="251.2603"
          y1="467.4629"
          x2="352.4422"
          y2="540.1874"
        >
          <stop offset="0" style="stop-color: #ec1c24"></stop>
          <stop offset="0.2293" style="stop-color: #e21a22"></stop>
          <stop offset="0.6209" style="stop-color: #c6141b"></stop>
          <stop offset="1" style="stop-color: #a60e13"></stop>
        </linearGradient>
        <path
          class="Fun st1 el5"
          d="M180.7,388.7l-62.8,21.4c43.2,54.5,116.4,97,205.1,118.1l23.5-43.1C274.8,467.8,215.6,433.1,180.7,388.7z"
          :class="{
            dimOut: selectedZone1 !== 'Fun' && selectedZone2 !== 'Fun',
          }"
        ></path>
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="669.0256"
          y1="138.8181"
          x2="499.8607"
          y2="70.8359"
        >
          <stop offset="0" style="stop-color: #00b17e"></stop>
          <stop offset="1" style="stop-color: #007d58"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Trustworthy' &&
              selectedZone2 !== 'Trustworthy',
          }"
          class="Trustworthy st2 el6"
          d="M536.9,56.9l-15.8,43.3c85.5,13.5,157,51,197.4,101.2l60.6-21.5C729.2,118.9,641.6,73.4,536.9,56.9z"
        ></path>
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="663.0578"
          y1="439.0703"
          x2="783.2114"
          y2="291.2499"
        >
          <stop offset="0" style="stop-color: #2b388f"></stop>
          <stop offset="1" style="stop-color: #0e0d50"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Nurturing' && selectedZone2 !== 'Nurturing',
          }"
          class="Nurturing st3 el7"
          d="M526.4,490.7l17.6,44c109.1-18.7,198.8-69.1,245.2-135.3l-63.3-19C688.1,434.5,615.1,475.6,526.4,490.7z"
        ></path>
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1="238.7217"
          y1="151.5906"
          x2="139.12"
          y2="134.1998"
        >
          <stop offset="0" style="stop-color: #f6921e"></stop>
          <stop offset="0.3109" style="stop-color: #f5881f"></stop>
          <stop offset="0.8423" style="stop-color: #f26c21"></stop>
          <stop offset="1" style="stop-color: #f16322"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Inspiring' && selectedZone2 !== 'Inspiring',
          }"
          class="Inspiring st4 el8"
          d="M321.5,113.6l-28.4-40.9c-89.4,27.2-159.7,76.9-194.2,138.1l65.8,16.3C192.3,176.9,249.1,136.1,321.5,113.6z"
        ></path>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Interesting' &&
              selectedZone2 !== 'Interesting',
          }"
          class="Interesting st0 el9"
          d="M75.7,296.4c0-30.1,8.2-58.9,23.2-85.6l-71.3-17.6c-18.1,32.1-28,66.9-28,103.2c0,49.3,18.3,95.9,50.7,136.8
                              l67.6-23.1C91,376.1,75.7,337.4,75.7,296.4z"
        ></path>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Familiar' && selectedZone2 !== 'Familiar',
          }"
          class="Familiar st0 el10"
          d="M846,156l-67,23.8c28.3,34.7,44.4,74.4,44.4,116.5c0,36.8-12.3,71.7-34.2,103l69.3,20.8
                              c26.3-37.7,41-79.6,41-123.8C899.6,245.6,880.2,197.8,846,156z"
        ></path>
        <linearGradient
          id="SVGID_5_"
          gradientUnits="userSpaceOnUse"
          x1="777.3427"
          y1="392.1554"
          x2="764.9913"
          y2="136.0698"
        >
          <stop offset="0" style="stop-color: #999999"></stop>
          <stop offset="1" style="stop-color: #737373"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Familiar' && selectedZone2 !== 'Familiar',
          }"
          class="Familiar st5 el11"
          d="M754,295.9c0,30.2-10,58.8-28,84.6l63.3,19c22-31.4,34.2-66.3,34.2-103c0-42.2-16.1-81.8-44.4-116.5
                              l-60.6,21.5C741.1,229.6,754,261.7,754,295.9z"
        ></path>
        <linearGradient
          id="SVGID_6_"
          gradientUnits="userSpaceOnUse"
          x1="139.078"
          y1="276.452"
          x2="122.478"
          y2="365.776"
        >
          <stop offset="0" style="stop-color: #f9ec31"></stop>
          <stop offset="0.4074" style="stop-color: #f7e327"></stop>
          <stop offset="1" style="stop-color: #f4cf10"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Interesting' &&
              selectedZone2 !== 'Interesting',
          }"
          class="Interesting st6 el12"
          d="M146.5,295.9c0-24.2,6.4-47.3,18.2-68.8l-65.8-16.3c-15,26.7-23.2,55.5-23.2,85.6c0,41,15.2,79.7,42.2,113.7
                              l62.8-21.4C158.9,360.9,146.5,329.4,146.5,295.9z"
        ></path>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Friendly' && selectedZone2 !== 'Friendly',
          }"
          class="Friendly st0 el13"
          d="M449.6,542.7c-44.4,0-87-5.1-126.5-14.5l-25.7,47.2c47.6,11.3,98.8,17.4,152.3,17.4c39.3,0,77.5-3.3,113.8-9.6
                              L544,534.7C513.8,539.9,482.2,542.7,449.6,542.7z"
        ></path>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Knowledgeable' &&
              selectedZone2 !== 'Knowledgeable',
          }"
          class="Knowledgeable st0 el14"
          d="M449.6,50.1c30.1,0,59.3,2.3,87.3,6.8l17.9-48.8C521,2.8,485.8,0,449.6,0C382.4,0,318.7,9.7,261.5,27
                              l31.7,45.6C340.7,58.2,393.7,50.1,449.6,50.1z"
        ></path>
        <linearGradient
          id="SVGID_7_"
          gradientUnits="userSpaceOnUse"
          x1="410.1664"
          y1="514.3226"
          x2="661.5415"
          y2="485.8651"
        >
          <stop offset="0" style="stop-color: #90278e"></stop>
          <stop offset="1" style="stop-color: #5b0059"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Friendly' && selectedZone2 !== 'Friendly',
          }"
          class="Friendly st7 el15"
          d="M450.2,497.1c-36.4,0-71.3-4.2-103.7-12L323,528.2c39.5,9.4,82.1,14.5,126.5,14.5c32.6,0,64.3-2.8,94.4-7.9
                              l-17.6-44C502,494.9,476.5,497.1,450.2,497.1z"
        ></path>
        <linearGradient
          id="SVGID_8_"
          gradientUnits="userSpaceOnUse"
          x1="425.3801"
          y1="85.9758"
          x2="390.5985"
          y2="14.8318"
        >
          <stop offset="0" style="stop-color: #804022"></stop>
          <stop offset="0.2965" style="stop-color: #76381b"></stop>
          <stop offset="0.8034" style="stop-color: #5a230a"></stop>
          <stop offset="1" style="stop-color: #4e1902"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Knowledgeable' &&
              selectedZone2 !== 'Knowledgeable',
          }"
          class="Knowledgeable st8 el16"
          d="M450.2,94.7c24.4,0,48.1,1.9,70.8,5.5l15.8-43.3c-28-4.4-57.3-6.8-87.3-6.8c-55.9,0-108.8,8.1-156.4,22.5
                              l28.4,40.9C360.6,101.5,404.2,94.7,450.2,94.7z"
        ></path>
        <linearGradient
          id="SVGID_9_"
          gradientUnits="userSpaceOnUse"
          x1="328.718"
          y1="378.0399"
          x2="410.9282"
          y2="303.7345"
        >
          <stop offset="0" style="stop-color: #f7aea5"></stop>
          <stop offset="0.2878" style="stop-color: #f6a4a0"></stop>
          <stop offset="0.7797" style="stop-color: #f38894"></stop>
          <stop offset="1" style="stop-color: #f27a8d"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut: selectedZone1 !== 'Fun' && selectedZone2 !== 'Fun',
          }"
          class="Fun st9 el17"
          d="M346.5,332.1l-165.8,56.6c34.9,44.4,94.1,79.1,165.9,96.4l63.6-116.7C382.6,361.9,359.9,348.9,346.5,332.1z"
        ></path>
        <linearGradient
          id="SVGID_10_"
          gradientUnits="userSpaceOnUse"
          x1="588.5313"
          y1="193.7156"
          x2="537.94"
          y2="263.2785"
        >
          <stop offset="0" style="stop-color: #58be96"></stop>
          <stop offset="1" style="stop-color: #4aa481"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Trustworthy' &&
              selectedZone2 !== 'Trustworthy',
          }"
          class="Trustworthy st10 el18"
          d="M552.7,260.3l165.7-58.9c-40.4-50.2-111.8-87.7-197.4-101.2l-44.7,122.1C509.3,227.3,537,241.4,552.7,260.3z"
        ></path>
        <linearGradient
          id="SVGID_11_"
          gradientUnits="userSpaceOnUse"
          x1="578.0821"
          y1="377.0156"
          x2="527.4916"
          y2="315.3584"
        >
          <stop offset="0" style="stop-color: #8ac5eb"></stop>
          <stop offset="1" style="stop-color: #6a96b4"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Nurturing' && selectedZone2 !== 'Nurturing',
          }"
          class="Nurturing st11 el19"
          d="M478.4,370.7l48,120.1c88.7-15.2,161.7-56.2,199.5-110.3l-170.4-51.1C540.7,349.7,512.5,365.1,478.4,370.7z"
        ></path>
        <linearGradient
          id="SVGID_12_"
          gradientUnits="userSpaceOnUse"
          x1="289.8135"
          y1="206.0066"
          x2="373.605"
          y2="270.0359"
        >
          <stop offset="0" style="stop-color: #fbb973"></stop>
          <stop offset="0.327" style="stop-color: #fab269"></stop>
          <stop offset="0.8861" style="stop-color: #f8a04d"></stop>
          <stop offset="1" style="stop-color: #f89c47"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Inspiring' && selectedZone2 !== 'Inspiring',
          }"
          class="Inspiring st12 el20"
          d="M400.6,227.5l-79-113.9c-72.4,22.5-129.2,63.3-156.8,113.5l175.4,43.4C350.8,251.5,372.7,236,400.6,227.5z"
        ></path>
        <linearGradient
          id="SVGID_13_"
          gradientUnits="userSpaceOnUse"
          x1="713.0676"
          y1="290.9235"
          x2="616.726"
          y2="290.9235"
        >
          <stop offset="0" style="stop-color: #c5c7c9"></stop>
          <stop offset="1" style="stop-color: #a6a6a6"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Familiar' && selectedZone2 !== 'Familiar',
          }"
          class="Familiar st13 el21"
          d="M566.8,296.7c0,11.7-4,22.8-11.2,32.7l170.4,51.1c18-25.7,28-54.4,28-84.6c0-34.2-12.9-66.3-35.5-94.5
                              l-165.7,58.9C561.7,271.1,566.8,283.5,566.8,296.7z"
        ></path>
        <linearGradient
          id="SVGID_14_"
          gradientUnits="userSpaceOnUse"
          x1="244.1125"
          y1="304.9641"
          x2="436.9913"
          y2="297.0592"
        >
          <stop offset="0" style="stop-color: #faf183"></stop>
          <stop offset="0.1646" style="stop-color: #faec79"></stop>
          <stop offset="0.4469" style="stop-color: #f8de5d"></stop>
          <stop offset="0.8104" style="stop-color: #f6c931"></stop>
          <stop offset="1" style="stop-color: #f5bc17"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Interesting' &&
              selectedZone2 !== 'Interesting',
          }"
          class="Interesting st14 el22"
          d="M333.1,296.7c0-9.2,2.5-18,7.1-26.2l-175.4-43.4c-11.8,21.5-18.2,44.6-18.2,68.8c0,33.5,12.3,65,34.2,92.8
                              l165.8-56.6C337.9,321.5,333.1,309.5,333.1,296.7z"
        ></path>
        <linearGradient
          id="SVGID_15_"
          gradientUnits="userSpaceOnUse"
          x1="437.4375"
          y1="426.6608"
          x2="439.809"
          y2="277.2582"
        >
          <stop offset="0" style="stop-color: #c59bc7"></stop>
          <stop offset="0.1249" style="stop-color: #bd91be"></stop>
          <stop offset="0.3392" style="stop-color: #a775a5"></stop>
          <stop offset="0.6169" style="stop-color: #84497c"></stop>
          <stop offset="0.9442" style="stop-color: #540b44"></stop>
          <stop offset="1" style="stop-color: #4b003a"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Friendly' && selectedZone2 !== 'Friendly',
          }"
          class="Friendly st15 el23"
          d="M449.9,372.9c-14,0-27.4-1.6-39.8-4.5l-63.6,116.7c32.4,7.8,67.3,12,103.7,12c26.3,0,51.8-2.2,76.2-6.4
                              l-48-120.1C469.3,372.1,459.7,372.9,449.9,372.9z"
        ></path>
        <linearGradient
          id="SVGID_16_"
          gradientUnits="userSpaceOnUse"
          x1="423.8921"
          y1="80.663"
          x2="408.115"
          y2="240.4053"
        >
          <stop offset="0" style="stop-color: #aa7e62"></stop>
          <stop offset="0.4751" style="stop-color: #a5745d"></stop>
          <stop offset="1" style="stop-color: #9e6356"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Knowledgeable' &&
              selectedZone2 !== 'Knowledgeable',
          }"
          class="Knowledgeable st16 el24"
          d="M321.5,113.6l79,113.9c15-4.6,31.7-7.1,49.3-7.1c9.1,0,17.9,0.7,26.4,2l44.7-122.1
                              c-22.7-3.6-46.4-5.5-70.8-5.5C404.2,94.7,360.6,101.5,321.5,113.6z"
        ></path>
        <linearGradient
          id="SVGID_17_"
          gradientUnits="userSpaceOnUse"
          x1="346.4608"
          y1="332.8404"
          x2="448.8942"
          y2="332.8404"
        >
          <stop offset="0" style="stop-color: #fbd5ce"></stop>
          <stop offset="0.3812" style="stop-color: #facbc8"></stop>
          <stop offset="1" style="stop-color: #f7b1ba"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut: selectedZone1 !== 'Fun' && selectedZone2 !== 'Fun',
          }"
          class="Fun st17 el25"
          d="M448.6,297.3l-102.2,34.9c13.5,16.7,36.2,29.8,63.7,36.3l38.7-71.1L448.6,297.3z"
        ></path>
        <linearGradient
          id="SVGID_18_"
          gradientUnits="userSpaceOnUse"
          x1="497.2629"
          y1="260.365"
          x2="451.4155"
          y2="310.9553"
        >
          <stop offset="0" style="stop-color: #b0dcc8"></stop>
          <stop offset="1" style="stop-color: #85a799"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Trustworthy' &&
              selectedZone2 !== 'Trustworthy',
          }"
          class="Trustworthy st18 el26"
          d="M552.7,260.3c-15.7-18.9-43.4-33-76.3-38L449,297.2L552.7,260.3z"
        ></path>
        <linearGradient
          id="SVGID_19_"
          gradientUnits="userSpaceOnUse"
          x1="504.2613"
          y1="339.0582"
          x2="407.032"
          y2="255.2671"
        >
          <stop offset="0" style="stop-color: #cbe2f5"></stop>
          <stop offset="1" style="stop-color: #8c9dac"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Nurturing' && selectedZone2 !== 'Nurturing',
          }"
          class="Nurturing st19 el27"
          d="M478.4,370.7c34.2-5.6,62.4-21,77.2-41.3l-106.5-31.9L478.4,370.7z"
        ></path>
        <linearGradient
          id="SVGID_20_"
          gradientUnits="userSpaceOnUse"
          x1="382.1138"
          y1="249.9289"
          x2="408.1996"
          y2="321.0721"
        >
          <stop offset="0" style="stop-color: #ffe1c1"></stop>
          <stop offset="0.2579" style="stop-color: #fed8b7"></stop>
          <stop offset="0.6987" style="stop-color: #fbc19b"></stop>
          <stop offset="1" style="stop-color: #f9ae85"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Inspiring' && selectedZone2 !== 'Inspiring',
          }"
          class="Inspiring st20 el28"
          d="M340.1,270.5l108.5,26.8l0.1,0l0.2-0.1l-48.4-69.7C372.7,236,350.8,251.5,340.1,270.5z"
        ></path>
        <linearGradient
          id="SVGID_21_"
          gradientUnits="userSpaceOnUse"
          x1="536.7336"
          y1="295.3947"
          x2="417.3684"
          y2="299.3472"
        >
          <stop offset="0" style="stop-color: #dbdcdd"></stop>
          <stop offset="1" style="stop-color: #919191"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Familiar' && selectedZone2 !== 'Familiar',
          }"
          class="Familiar st21 el29"
          d="M449,297.2l0.1,0.1l0,0l106.5,31.9c7.2-9.9,11.2-21,11.2-32.7c0-13.2-5.1-25.5-14.1-36.4L449,297.2L449,297.2
                              z"
        ></path>
        <linearGradient
          id="SVGID_22_"
          gradientUnits="userSpaceOnUse"
          x1="357.4624"
          y1="288.4954"
          x2="460.2253"
          y2="355.6866"
        >
          <stop offset="0" style="stop-color: #f3f4c5"></stop>
          <stop offset="0.1294" style="stop-color: #f3f3bb"></stop>
          <stop offset="0.3513" style="stop-color: #f5f29f"></stop>
          <stop offset="0.639" style="stop-color: #f6f073"></stop>
          <stop offset="0.9781" style="stop-color: #f9ec35"></stop>
          <stop offset="1" style="stop-color: #f9ec31"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Interesting' &&
              selectedZone2 !== 'Interesting',
          }"
          class="Interesting st22 el30"
          d="M340.1,270.5c-4.6,8.2-7.1,17-7.1,26.2c0,12.8,4.8,24.9,13.4,35.5l102.2-34.9L340.1,270.5z"
        ></path>
        <linearGradient
          id="SVGID_23_"
          gradientUnits="userSpaceOnUse"
          x1="444.3013"
          y1="364.2184"
          x2="445.0917"
          y2="261.4582"
        >
          <stop offset="0" style="stop-color: #dcc6e0"></stop>
          <stop offset="1" style="stop-color: #9d8ea2"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Friendly' && selectedZone2 !== 'Friendly',
          }"
          class="Friendly st23 el31"
          d="M448.9,297.4L448.9,297.4l-38.8,71.1c12.4,2.9,25.8,4.5,39.8,4.5c9.8,0,19.3-0.8,28.4-2.3l-29.3-73.3
                              L448.9,297.4z"
        ></path>
        <linearGradient
          id="SVGID_24_"
          gradientUnits="userSpaceOnUse"
          x1="436.4919"
          y1="193.289"
          x2="443.6061"
          y2="307.1171"
        >
          <stop offset="0" style="stop-color: #d9c4b6"></stop>
          <stop offset="0.2612" style="stop-color: #d1baae"></stop>
          <stop offset="0.7077" style="stop-color: #bc9e99"></stop>
          <stop offset="1" style="stop-color: #ac8989"></stop>
        </linearGradient>
        <path
          :class="{
            dimOut:
              selectedZone1 !== 'Knowledgeable' &&
              selectedZone2 !== 'Knowledgeable',
          }"
          class="Knowledgeable st24 el32"
          d="M449,297.2l27.4-74.8c-8.5-1.3-17.3-2-26.4-2c-17.6,0-34.4,2.6-49.3,7.1L449,297.2L449,297.2z"
        ></path>
        <polyline
          class="st25"
          points="434.9,371.6 435,371.6 435,371.6  "
        ></polyline>
        <polyline
          class="st25"
          points="430.9,371.2 431.1,371.2 431.1,371.3  "
        ></polyline>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Map",
  props: {
    selectedZone1: {
      type: String,
      default() {
        return "";
      },
    },
    selectedZone2: {
      type: String,
      default() {
        return "";
      },
    },
    selectedAttributes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    selectedAttributesDetails() {
      let attrs = this.selectedAttributes.map((attr) => {
        return {
          ...attr,
          xPos: Math.round(
            ((2 + attr.X) / 4) * 0.949 * 1000 + (0.051 * 1000) / 2
          ),
          yPos: Math.round(
            ((2 - attr.Y) / 4) * 0.935 * 670 + (0.065 * 670) / 2
          ),
        };
      });
      return attrs;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.25s ease-out;
}

.list-enter-to {
  .map__trigger-title {
    opacity: 0;
    transition: none;
  }
}

.list-enter {
  opacity: 0;
  transform: scale(4.5);
}
.map {
  svg {
    transition: all 0.5s;

    .el1 {
      filter: drop-shadow(3px -5px 8px rgba(0, 0, 0, 0.55));
    }
    .el2 {
      filter: drop-shadow(0px 10px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el3 {
      filter: drop-shadow(0px 11px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el4 {
      filter: drop-shadow(-6px -10px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el5 {
      filter: drop-shadow(11px -8px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el6 {
      filter: drop-shadow(-8px 12px 9px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el7 {
      filter: drop-shadow(1px -6px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el8 {
      filter: drop-shadow(0px 10px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el9 {
      filter: drop-shadow(13px 0px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el10 {
      filter: drop-shadow(-9px 4px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el11 {
      filter: drop-shadow(-8px 0px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el12 {
      filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el13 {
      filter: drop-shadow(-1px -9px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el14 {
      filter: drop-shadow(0px 10px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el15 {
      filter: drop-shadow(-1px -9px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el16 {
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el17 {
      filter: drop-shadow(3px -4px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el18 {
      filter: drop-shadow(0px 9px 10px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el19 {
      filter: drop-shadow(-10px -4px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el20 {
      filter: drop-shadow(0px 10px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el21 {
      filter: drop-shadow(-7px 4px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el22 {
      filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el23 {
      filter: drop-shadow(2px -5px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el24 {
      stroke: none !important;
    }
    .el25 {
      filter: drop-shadow(2px -5px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el26 {
      filter: drop-shadow(0px 10px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el27 {
      filter: drop-shadow(-10px -4px 12px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el28 {
      filter: drop-shadow(0px 10px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el29 {
      filter: drop-shadow(-7px 4px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el30 {
      filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el31 {
      filter: drop-shadow(-1px -5px 7px rgba(0, 0, 0, 0.55));
      stroke: none !important;
    }
    .el32 {
      filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.2));
      stroke: none !important;
    }

    .st0 {
      stroke: none !important;
      fill: #231f20;
      stroke: #ffffff;
    }

    .st1 {
      fill: url(#SVGID_1_);
    }

    .st2 {
      fill: url(#SVGID_2_);
      filter: drop-shadow(0px 11px 12px rgba(0, 0, 0, 0.55));
    }

    .st3 {
      fill: url(#SVGID_3_);
    }

    .st4 {
      fill: url(#SVGID_4_);
    }

    .st5 {
      fill: url(#SVGID_5_);
    }

    .st6 {
      fill: url(#SVGID_6_);
    }

    .st7 {
      fill: url(#SVGID_7_);
    }

    .st8 {
      fill: url(#SVGID_8_);
      filter: drop-shadow(0px 10px 12px rgba(0, 0, 0, 0.55));
    }

    .st9 {
      fill: url(#SVGID_9_);
    }

    .st10 {
      fill: url(#SVGID_10_);
    }

    .st11 {
      fill: url(#SVGID_11_);
    }

    .st12 {
      fill: url(#SVGID_12_);
    }

    .st13 {
      fill: url(#SVGID_13_);
    }

    .st14 {
      fill: url(#SVGID_14_);
    }

    .st15 {
      fill: url(#SVGID_15_);
    }

    .st16 {
      fill: url(#SVGID_16_);
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.55));
    }

    .st17 {
      fill: url(#SVGID_17_);
    }

    .st18 {
      fill: url(#SVGID_18_);
    }

    .st19 {
      fill: url(#SVGID_19_);
    }

    .st20 {
      fill: url(#SVGID_20_);
    }

    .st21 {
      fill: url(#SVGID_21_);
    }

    .st22 {
      fill: url(#SVGID_22_);
    }

    .st23 {
      fill: url(#SVGID_23_);
    }

    .st24 {
      fill: url(#SVGID_24_);
      filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.2));
    }

    .st25 {
      fill: none;
    }

    .st27 {
      font-size: 16px;
    }
    .dimOut {
      filter: grayscale(60%) opacity(40%);
    }
  }
  &__circle {
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    // white-space: nowrap;
    display: flex;
    justify-content: center;
    width: 10px;
    height: 10px;
    text-transform: capitalize;
    &:hover {
      z-index: 2;
      .map__trigger-title {
        background: rgba(255, 255, 255, 0.8);
        padding: 0 2px;
        border-radius: 3px;
        font-weight: 500;
      }
    }
    &.is-active {
      .map__tooltip {
        visibility: visible;
        opacity: 1;
        transform: translateY(-108%);
        z-index: 1;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
      }
    }
    &.pointer-none {
      pointer-events: none;
    }
  }
  &__trigger {
    border-radius: 50%;
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: 1;
    background: #369be1;
    color: rgba(54, 155, 225, 0.36);
    border: 1px solid #abd4f0;
    &:hover {
      box-shadow: 0 0 0 3px;
      animation: a 1.3s infinite;
      ~ .map__tooltip {
        visibility: visible;
        opacity: 1;
        bottom: calc(100% + 7px);
        z-index: 2;
      }
    }
  }
  &__trigger-title {
    position: absolute;
    top: calc(100% + 4px);
    font-size: 11px;
    transition: all 0.25s ease-out;
    line-height: 15px;
    white-space: nowrap;
    pointer-events: none;
  }
  &__tooltip {
    position: absolute;
    bottom: calc(100% + 12px);
    font-size: 12px;
    padding: 6px 7px;
    cursor: default;
    border-radius: 4px;
    background: #fefefe;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 7px -3px;
    width: 200px;
    &:hover {
      z-index: 2 !important;
    }
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: #fefefe transparent transparent transparent;
      left: calc(50% - 5px);
      top: 100%;
    }
    &.is-hide {
      display: none;
    }
    span {
      display: block;
      line-height: 15px;
      &:first-child {
        margin-bottom: 1px;
      }
    }
    strong {
      font-weight: 500;
    }
  }
}

@keyframes a {
  0% {
    box-shadow: 0 0 0 0;
  }

  12% {
    box-shadow: 0 0 0 1px;
  }

  25% {
    box-shadow: 0 0 0 2px;
  }

  36% {
    box-shadow: 0 0 0 3px;
  }

  50% {
    box-shadow: 0 0 0 4px;
  }

  62% {
    box-shadow: 0 0 0 3px;
  }

  75% {
    box-shadow: 0 0 0 2px;
  }

  87% {
    box-shadow: 0 0 0 1px;
  }

  to {
    box-shadow: 0 0 0 0;
  }
}
</style>
