<template>
  <div class="objectives">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item
          ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
        >
        <a-breadcrumb-item><a href="/products">Products</a></a-breadcrumb-item>
        <a-breadcrumb-item>Pack Test</a-breadcrumb-item>
      </template>
    </a-breadcrumb>

    <ProgressBar :stepIndex="stepIndex" :surveyID="surveyID" stepsType="pack" />
    <CompletionProgress :checkList="quizs"></CompletionProgress>
    <div class="objectives__wrapper" id="objectivesWrapper">
      <div class="objectives__inner flex">
        <div class="objectives__left-col flex-1">
          <p class="objectives__info">
            Based on your packaging objectives please choose the key target
            equity associations you’re looking to reinforce. A primary objective
            is required but this methodology supports a secondary if applicable.
          </p>
          <Loader
            text="Loading data..."
            :paddingTop="150"
            v-if="loading"
          ></Loader>
          <div v-else>
            <h2>Communication Objective</h2>
            <div class="flex items-start objectives__form">
              <div class="flex-1">
                <div class="objectives__select">
                  <label>Primary communication objective</label>
                  <a-select
                    class="fullWidth"
                    option-filter-prop="children"
                    show-search
                    placeholder="Select communication objective"
                    @change="selectObjective1"
                    :key="Math.random()"
                    :default-value="
                      chosenCommunicationObjective1 !== 0
                        ? chosenCommunicationObjective1
                        : undefined
                    "
                  >
                    <a-select-option
                      v-for="item in communicationObjectives"
                      :key="item.CommunicationObjectiveID"
                      :value="item.CommunicationObjectiveID"
                    >
                      {{ item.CommunicationObjectiveName }}
                    </a-select-option>
                  </a-select>
                </div>
                <div
                  class="objectives__add-more"
                  @click="showCommunicationObjective2 = true"
                  v-if="
                    !showCommunicationObjective2 &&
                    chosenCommunicationObjective1 !== 0
                  "
                >
                  + <span>Add second communication objective</span>
                </div>
                <div
                  class="objectives__select"
                  v-if="showCommunicationObjective2"
                >
                  <label>Second communication objective</label>
                  <a-select
                    class="fullWidth"
                    option-filter-prop="children"
                    show-search
                    placeholder="Select communication objective"
                    :key="Math.random()"
                    :default-value="
                      chosenCommunicationObjective2 !== 0
                        ? chosenCommunicationObjective2
                        : undefined
                    "
                    @change="selectObjective2"
                  >
                    <a-select-option
                      v-for="item in filteredCommunicationObjectives"
                      :key="item.CommunicationObjectiveID"
                      :value="item.CommunicationObjectiveID"
                    >
                      {{ item.CommunicationObjectiveName }}
                    </a-select-option>
                  </a-select>

                  <a-tooltip
                    placement="top"
                    overlayClassName="ant-tooltip--delete-objective"
                  >
                    <template slot="title"> Delete </template>
                    <button
                      type="button"
                      class="btn-reset objectives__select-remove"
                      @click="removeCommunicationObjective"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </button>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <h2 id="emotionalAttributes">Emotional Attributes</h2>
            <div class="objectives__form">
              <div
                :style="{ height: boxHeight + 'px' }"
                class="overflow-hidden objectives__form-attributes"
              >
                <div class="flex-1" id="emotionalAttributesContent">
                  <div class="objectives__select objectives__select--full">
                    <label
                      >Please select 3-9 attributes to reflect the way you would
                      like your packaging to convey the concepts of
                      {{ selectedCO1Name }}
                      <span v-if="selectedCO2Name !== null">
                        and {{ selectedCO2Name }}</span
                      ></label
                    >
                    <div class="objectives__select-inner">
                      <p v-if="chosenCommunicationObjective1 === 0">
                        To see the key attributes list you need to select
                        Communication objective.
                      </p>
                      <div
                        v-else
                        class="custom-checkbox"
                        v-for="item in activeAttributesList"
                        :key="item.atributeID"
                      >
                        <input
                          type="checkbox"
                          :value="item"
                          :id="item.attributeID"
                          v-model="chosenAttributes"
                          @change="setRobotValidation()"
                        />
                        <label :for="item.attributeID">{{
                          item.AttributeLabel
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="objectives__map" id="emotionalAttributesContent1">
                  <h3>
                    Communication Territory
                    <a-tooltip
                      placement="top"
                      overlayClassName="ant-tooltip--step-nav"
                    >
                      <template slot="title">
                        <span>
                          Emotional attributes are used to measure the coherency
                          of your packaging with your brand strategy. How does
                          your brand and packaging deliver on key category
                          drivers?
                          <span @click="showModal" class="cursor-pointer"
                            ><u>Click to learn more</u></span
                          >
                          about how Hotspex uses this powerful methodology.
                        </span>
                      </template>
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </h3>
                  <div class="objectives__map-inner">
                    <div class="objectives__map-image">
                      <!-- <img src="/images/mapExplorer.svg" alt="" /> -->
                      <Map
                        :selectedZone1="selectedZone1Name"
                        :selectedZone2="selectedZone2Name"
                        :selectedAttributes="chosenAttributes"
                      ></Map>
                      <a-tooltip
                        placement="top"
                        overlayClassName="ant-tooltip--step-nav"
                      >
                        <template slot="title">
                          <span>
                            Emotional attributes are used to measure the
                            coherency of your packaging with your brand
                            strategy. How does your brand and packaging deliver
                            on key category drivers?
                            <span @click="showModal" class="cursor-pointer"
                              ><u>Click to learn more</u></span
                            >
                            about how Hotspex uses this powerful methodology.
                          </span>
                        </template>
                        <a-icon type="question-circle" />
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="objectives__select-inner"
                v-if="chosenCommunicationObjective1 === 0"
              >
                <p class="mb-0 font-size-14">
                  Please select a Communication Objective above.
                </p>
              </div>
            </div>
            <h2>Key messages</h2>
            <div class="objectives__form objectives__form--key">
              <p>
                Our standard pack test methodology uses predefined
                category-specific statements which are delivered in every
                report. The predefined statements for this category can be seen
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--step-nav"
                >
                  <template slot="title">
                    <ul class="list-reset pl-10 list-type-disc">
                      <li v-for="item in categoryStatements" :key="item.id">
                        {{ item.CogniName }}
                      </li>
                    </ul> </template
                  ><span class="cursor-pointer"><u>here</u></span> </a-tooltip
                >.
              </p>
              <p class="mb-20">
                In addition to these predefined statements, up to 5 custom
                statements can be added below:
              </p>
              <div class="objectives__key-list ant-input-group-addon-small">
                <a-input
                  v-for="(option, index) in customKey"
                  placeholder="Add custom statement"
                  class="options-input mb-10"
                  :key="index"
                  v-model="option.optionText"
                  @pressEnter="addCustomKey(index)"
                >
                  <a-button
                    slot="addonAfter"
                    v-if="
                      option.optionText === '' ||
                      (customKey[index + 1] === undefined &&
                        index + 1 < maxCustomKey)
                    "
                    @click="addCustomKey(index)"
                  >
                    Add
                  </a-button>
                  <a-button
                    v-else
                    slot="addonAfter"
                    class="ant-btn--delete"
                    @click="removeCustomKey(index)"
                  >
                    Delete
                  </a-button>
                </a-input>
              </div>
              <p class="font-size-13">
                *Please ensure that the wording of your statements is in the
                language of your study -
                <strong>{{
                  projectSummary !== null ? projectSummary.languageCode : ""
                }}</strong>
              </p>
            </div>
          </div>
        </div>
        <!-- <CorrectIndicator :quizs="quizs" /> -->
      </div>
    </div>
    <a-modal
      v-model="personaPreviewModal"
      width="90%"
      height="90vh"
      centered
      :footer="null"
      :header="false"
      wrapClassName="ant-modal--persona-iframe"
    >
      <iframe
        src="https://hotspex.com/persona/"
        width="100%"
        height="100%"
        frameborder="0"
      >
      </iframe>
    </a-modal>
    <StepNaviBar
      :stepIndex="stepIndex"
      :nextDisable="enableNextStep"
      :conciergeAssistance="true"
      @nextHandler="nextStep"
      :prevDisable="false"
      @prevHandler="prevStep"
      prevBtnTooltip="Back to Stimuli"
      nextBtnTooltip="Go to Audience"
      stepsType="pack"
    />
  </div>
</template>

<script>
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar.vue";
import Loader from "@/components/general/Loader.vue";
import Map from "@/components/general/Map.vue";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import _ from "lodash";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";

export default {
  name: "CommunicationObjective",
  components: {
    ProgressBar,
    StepNaviBar,
    Loader,
    CompletionProgress,
    Map,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      stepIndex: 3,
      langObj: {},
      client: {},
      quizs: [],
      attributes: [],
      communicationObjectives: [],
      surveyID: 0,
      personaPreviewModal: false,
      loading: false,
      selectedStatus: 0,
      chosenCommunicationObjective1: 0,
      chosenCommunicationObjective2: 0,
      chosenZone1Id: null,
      chosenZone2Id: null,
      showCommunicationObjective2: false,
      chosenAttributes: [],
      editableSurvey: undefined,
      customKey: [{ optionText: "" }],
      maxCustomKey: 5,
      projectSummary: null,
      categoryStatements: [],
      user: null,
      selectedZone1Name: "",
      selectedZone2Name: "",
      boxHeight: 0,
      selectedCO1Name: null,
      selectedCO2Name: null,
    };
  },
  computed: {
    enableNextStep() {
      return (
        this.chosenCommunicationObjective1 === 0 ||
        this.chosenAttributes.length < 3 ||
        this.chosenAttributes.length > 9
      );
    },
    activeAttributesList() {
      return this.attributes.filter((item) =>
        [this.chosenZone1Id, this.chosenZone2Id].includes(item.zoneID)
      );
    },
    filteredCommunicationObjectives() {
      let chosenCommunicationObjective1 = this.communicationObjectives.find(
        (item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }
      );
      let excludedCommunicationObjective = [
        chosenCommunicationObjective1.CommunicationObjectiveID,
      ];
      if (chosenCommunicationObjective1 !== undefined) {
        _.forEach(chosenCommunicationObjective1.Excluded, function (item) {
          excludedCommunicationObjective.push(item.ExcludedID);
        });
      }

      return this.communicationObjectives.filter(
        (item) =>
          !excludedCommunicationObjective.includes(
            item.CommunicationObjectiveID
          )
      );
    },
  },
  methods: {
    prevStep() {
      this.$router.push({
        name: "stimuli",
        params: { id: this.surveyID },
      });
    },
    nextStep() {
      this.save();
    },
    updateQuiz() {
      this.quizs = [
        {
          quizName: this.langObj["communication-objective"].robot1,
          completed: false,
        },
        {
          quizName: this.langObj["communication-objective"].robot2,
          completed: false,
        },
      ];
    },
    showModal() {
      this.personaPreviewModal = true;
    },
    addCommunicationObjective() {
      let _this = this;
      this.$confirm({
        title: "Add second communication objective",
        content: () => (
          <div>
            Adding a second objective significantly increases the cost of the
            study. Do you want to add it?
          </div>
        ),
        onOk() {
          _this.showCommunicationObjective2 = true;
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    removeCommunicationObjective() {
      let _this = this;
      this.$confirm({
        title: "Remove second communication objective",
        content: () => (
          <div>
            Are you sure you want to delete second communication objective?
          </div>
        ),
        onOk() {
          _this.chosenCommunicationObjective2 = 0;
          _this.showCommunicationObjective2 = false;
          _this.chosenZone2Id = null;
          _this.selectedCO2Name = null;
          _this.selectedZone2Name = "";
          _this.clearChosenAttributes();
          _this.priceCalculation();
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    selectObjective1(data) {
      this.chosenCommunicationObjective1 = data;
      this.setRobotValidation();
      this.chosenZone1Id = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective1
        );
      }).CommunicationZones;
      this.selectedZone1Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective1
        );
      }).CommunicationZonesNames;
      this.selectedCO1Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective1
        );
      }).CommunicationObjectiveName;
      this.chosenCommunicationObjective2 = 0;
      this.chosenZone2Id = null;
      this.clearChosenAttributes();
      setTimeout(() => {
        this.setHeightToEmotionalAttributes();
      }, 0);
      this.priceCalculation();
    },
    selectObjective2(data) {
      this.chosenCommunicationObjective2 = data;
      this.chosenZone2Id = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective2
        );
      }).CommunicationZones;
      this.selectedZone2Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective2
        );
      }).CommunicationZonesNames;
      this.selectedCO2Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective2
        );
      }).CommunicationObjectiveName;
      this.clearChosenAttributes();
      setTimeout(() => {
        this.setHeightToEmotionalAttributes();
      }, 0);
      this.priceCalculation();
    },
    scrollToEmotionalAttributes() {
      let emotionalAttributesOffset = document.getElementById(
        "emotionalAttributes"
      ).offsetTop;
      document.getElementById("objectivesWrapper").scrollTo({
        top: emotionalAttributesOffset - 180,
        behavior: "smooth",
      });
    },
    setHeightToEmotionalAttributes() {
      let h = document.getElementById(
        "emotionalAttributesContent"
      ).offsetHeight;
      let h1 = document.getElementById(
        "emotionalAttributesContent1"
      ).offsetHeight;
      this.boxHeight = h + h1 + 25;
    },
    setRobotValidation() {
      if (
        this.chosenAttributes.length > 2 &&
        this.chosenAttributes.length < 10
      ) {
        this.quizs[1].completed = true;
      } else {
        this.quizs[1].completed = false;
      }

      if (this.chosenCommunicationObjective1 !== 0) {
        this.quizs[0].completed = true;
      } else {
        this.quizs[0].completed = false;
      }
    },
    setCommunicationObjectives() {
      const getCommunicationObjectives = new Promise((resolve) => {
        wsUtils.GetCommunicationObjectives(resolve);
      });
      getCommunicationObjectives.then((data) => {
        this.communicationObjectives = data;
      });
    },
    setAttributes() {
      const personaListPromise = new Promise((resolve) => {
        wsUtils.getPersonaAttributes(resolve);
      });
      personaListPromise.then((data) => {
        this.attributes = data;
        this.setEditMode();
        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
    },
    setEditMode() {
      if (this.editableSurvey !== undefined) {
        this.setCOEdit();
        this.setKeyMessagesEdit();
      }
    },
    setCOEdit() {
      if (
        this.editableSurvey.SurveyConfig.CommunicationObjective1 !== -1 &&
        this.editableSurvey.SurveyConfig.CommunicationObjective1 !== 0
      ) {
        this.boxHeight = "auto";
        this.chosenCommunicationObjective1 =
          this.editableSurvey.SurveyConfig.CommunicationObjective1;
        this.chosenCommunicationObjective2 =
          this.editableSurvey.SurveyConfig.CommunicationObjective2;

        this.chosenZone1Id = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }).CommunicationZones;

        this.selectedZone1Name = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }).CommunicationZonesNames;

        this.selectedCO1Name = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }).CommunicationObjectiveName;

        let zone2 = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective2
          );
        });

        if (zone2 !== undefined) {
          this.chosenZone2Id = zone2.CommunicationZones;
          this.selectedZone2Name = zone2.CommunicationZonesNames;
          this.selectedCO2Name = zone2.CommunicationObjectiveName;
        }

        _.forEach(this.editableSurvey.AttributeDetails, (item) => {
          let attribute = this.attributes.find((attr) => {
            return attr.attributeID === item.attributeID;
          });
          this.chosenAttributes.push(attribute);
        });

        this.showCommunicationObjective2 =
          this.chosenCommunicationObjective2 !== 0 ? true : false;
        this.setRobotValidation();
      }
    },
    setKeyMessagesEdit() {
      if (
        this.editableSurvey &&
        this.editableSurvey.keyMessages &&
        this.editableSurvey.keyMessages.length > 0
      ) {
        this.customKey = [];
        _.forEach(this.editableSurvey.keyMessages, (item) => {
          this.customKey.push({ optionText: item });
        });
      }
    },
    clearChosenAttributes() {
      this.chosenAttributes = this.chosenAttributes.filter((item) =>
        [this.chosenZone1Id, this.chosenZone2Id].includes(item.zoneID)
      );
      this.setRobotValidation();
    },
    save() {
      let getKeyMessage = this.customKey
        .filter((item) => item.optionText !== "")
        .map((item) => {
          return item.optionText;
        });

      const selectIDs = this.chosenAttributes.map((attr) => attr.attributeID);
      const savePackTestPersonaAttributes = new Promise((resolve) => {
        wsUtils.SavePackTestPersonaAttributes(
          {
            surveyID: this.surveyID,
            attributeIDs: selectIDs.toString(),
            CommunicationObjective1: this.chosenCommunicationObjective1,
            CommunicationObjective2: this.chosenCommunicationObjective2,
            keaMessage: getKeyMessage,
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      savePackTestPersonaAttributes.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        this.setSurveySummaryData();
        this.$router.push({
          name: "pack-test-launch",
          params: { id: this.surveyID },
        });
      });
    },
    resetData() {
      this.chosenCommunicationObjective1 = 0;
      this.chosenCommunicationObjective2 = 0;
      this.chosenZone1Id = null;
      this.chosenZone2Id = null;
      this.chosenAttributes = [];
      this.showCommunicationObjective2 = false;
      this.boxHeight = 0;
      this.selectedCO2Name = null;
    },
    setSurveySummaryData() {
      let communicationObjectives = {
        chosenCommunicationObjective1:
          this.communicationObjectives.find((item) => {
            return (
              item.CommunicationObjectiveID ===
              this.chosenCommunicationObjective1
            );
          }) || -1,
        chosenCommunicationObjective2:
          this.communicationObjectives.find((item) => {
            return (
              item.CommunicationObjectiveID ===
              this.chosenCommunicationObjective2
            );
          }) || -1,
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({
        ...currentSummaryData,
        ...communicationObjectives,
      });
    },
    addCustomKey(index) {
      if (this.customKey[index].optionText !== "") {
        if (this.customKey.length < this.maxCustomKey) {
          this.customKey.push({ optionText: "" });
          setTimeout(() => {
            const optionInputs = document.querySelectorAll(
              ".options-input input"
            );
            optionInputs[this.customKey.length - 1].focus();
          }, 200);
        }
      }
    },
    removeCustomKey(index) {
      if (this.customKey.length === 1) {
        this.customKey[0] = { optionText: "" };
      } else {
        this.customKey.splice(index, 1);
      }
    },
    defaultCategoryStatements() {
      const getCategoryStatements = new Promise((resolve) => {
        wsUtils.GetCategoryStatements(
          {
            categoryID:
              this.projectSummary.activeShelf.categoryId !== undefined
                ? this.projectSummary.activeShelf.categoryId
                : this.editableSurvey.SurveyConfig.CategoryId,
          },
          resolve
        );
      });
      getCategoryStatements.then((data) => {
        this.categoryStatements = data;
      });
    },
    priceCalculation() {
      let comObjects;

      if (
        this.chosenCommunicationObjective1 !== 0 &&
        this.chosenCommunicationObjective1 !== -1
      ) {
        comObjects = 1;
      }
      if (
        this.chosenCommunicationObjective1 !== 0 &&
        this.chosenCommunicationObjective1 !== -1 &&
        this.chosenCommunicationObjective2 !== 0 &&
        this.chosenCommunicationObjective2 !== -1
      ) {
        comObjects = 2;
      }

      if (comObjects !== 0) {
        let currentPackTestPriceData = jsUtils.getPackTestPrice();
        jsUtils.setPackTestPrice({
          ...currentPackTestPriceData,
          comObjects: comObjects,
        });
        this.getPackTestTotalPrice();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetData();
    next();
  },
  activated() {
    this.user = jsUtils.getUserInfo();
    this.loading = true;
    this.client = jsUtils.getCurClient();
    this.surveyID = this.$route.params.id;
    this.projectSummary = jsUtils.getSurveySummaryData();
    this.editableSurvey = jsUtils.getEditableSurvey();

    this.updateQuiz();
    this.setAttributes();
    this.setCommunicationObjectives();
    this.defaultCategoryStatements();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
};
</script>

<style lang="scss" scoped>
.objectives {
  padding: 0 0 113px;
  text-align: left;
  .authorBtnSection {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
  }
  &__wrapper {
    height: calc(100vh - 168px);
    overflow: auto;
    padding: 0 20px;
  }
  &__inner {
    max-width: 710px;
    margin: 0 auto;
    padding: 50px 0 80px;
    min-height: 100%;
    ::v-deep .helpPanel {
      width: 250px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .robot {
        height: auto;
      }
    }
  }
  &__left-col {
    padding: 0 40px 60px 0;
    h2 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: #7f8487;
      margin-bottom: 5px;
    }
  }
  &__info {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
    @media screen and (max-width: 980px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  &__form {
    border: 1px solid #d2d2d2;
    padding: 30px 20px;
    border-radius: 8px;
    margin-bottom: 30px;

    &--key {
      p {
        font-size: 14px;
      }
    }

    &--overflow-hidden {
      overflow: hidden;
    }
  }
  &__form-attributes {
    transition: 1.2s ease all;
  }
  &__select {
    max-width: 410px;
    margin-bottom: 10px;
    position: relative;
    padding-top: 10px;
    &--full {
      max-width: 100%;
    }
    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    p {
      font-size: 13px;
      line-height: 17px;
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    ::v-deep .ant-select {
      min-width: 200px;
      .ant-select-selection--single {
        height: 40px;
        border-color: #d4d9dd;
        border-radius: 4px;
        .ant-select-selection__rendered {
          line-height: 38px;
        }
      }
    }
    > label {
      font-size: 14px;
      margin-bottom: 3px;
      color: #2c3e50;
      display: block;
      font-weight: 500;
    }
  }
  &__select-inner {
    background: #f8f9fd;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #f3f4f6;
  }
  &__select-remove {
    position: absolute;
    right: -48px;
    top: 43px;
    font-weight: 600;
    font-size: 11px;
    text-decoration: underline;
    color: var(--darkGrey);
    cursor: pointer;
    margin-right: 6px !important;
    border: 1px solid #6c6c6c !important;
    width: 23px;
    height: 23px;
    line-height: 22px;
    border-radius: 50%;
    color: #6c6c6c;
    text-align: center;
    @media screen and (max-width: 980px) {
      right: -5px;
      top: 6px;
    }
  }
  &__add-more {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 20px;
    span {
      text-decoration: underline;
      margin-left: 4px;
    }
  }
  &__map {
    margin-top: 25px;
    h3 {
      font-size: 14px;
      margin-bottom: 3px;
    }
    p {
      font-size: 13px;
      line-height: 15px;
      margin: 10px 0 0;
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }
  // &__map-inner {
  //   cursor: pointer;
  //   &:hover {
  //     p {
  //       opacity: 1;
  //     }
  //   }
  // }
  &__map-image {
    border-radius: 7px;
    background: #f8f9fd;
    padding: 20px;
    border: 1px solid #f3f4f6;
    transition: all 0.3s ease;
    position: relative;
    &:hover {
      background: #f1f2f6;
    }
    .anticon {
      position: absolute;
      bottom: 7px;
      right: 4px;
      ::v-deep svg {
        width: 30px;
        height: 25px;
        color: #6a6969;
      }
    }
  }
  &__key-list {
    max-width: 410px;
    ::v-deep .ant-input {
      height: 38px;
    }
    ::v-deep .ant-input-group-addon .ant-btn {
      height: 36px;
    }
  }
}
.custom-checkbox {
  margin: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  label {
    position: relative;
    user-select: none;
    cursor: pointer;
    font-size: 13px;
    display: inline-flex;
    padding: 3px 6px;
    border-radius: 7px;
    border: 1px solid #d4d9dd;
    background: #fff;
    transition: all 0.2s ease;
  }
  input {
    display: none;
    &:checked {
      + label {
        border-color: #369be1;
        outline: 2px;
        box-shadow: 0px 0px 0px 1px #369be1;
      }
    }
  }
}
</style>

<style lang="scss">
.ant-modal {
  pointer-events: auto;
  &--persona-iframe {
    z-index: 9999;
    .ant-modal-body {
      height: 90vh;
      padding: 0px;
    }
    .ant-modal-close {
      right: 20px;
      color: #ffffff;
    }
  }
}
.ant-tooltip-inner > span > u {
  cursor: pointer;
}

.ant-tooltip {
  &--delete-objective {
    font-size: 11px !important;
    .ant-tooltip-inner {
      padding: 4px 8px 1px;
      min-height: auto;
      background-color: #2c3e50;
      padding: 0 6px;
    }
  }
}
</style>
